<template>
  <div
    class="full-height"
  >
    <m-loader-circular
      v-if="loading"
      justify-center
      align-center
      column
      fill-height
    />

    <m-registration-steps
      v-else-if="validHash"
    />

    <m-registration-link-not-found
      v-else
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    components: {
      'm-registration-link-not-found': () => import('@/components/registration/MRegistrationLinkNotFound'),
      'm-registration-steps': () => import('@/components/registration/MRegistrationSteps'),
    },

    data: () => ({
      validHash: true,
      loading: false,
    }),

    computed: {
      registrationHash() {
        return this.$route.query.h;
      },
    },

    async created() {
      this.loading = true;

      const { registrationHash } = this;

      if (!registrationHash) {
        this.invalidate(); return;
      }

      try {
        await this.validateRegistrationHash({
          registrationHash,
        });
      } catch (e) {
        this.invalidate();
      }

      this.loading = false;
    },

    methods: {
      ...mapActions({
        validateRegistrationHash: 'registration/user/validateRegistrationHash',
      }),

      invalidate() {
        this.validHash = false;
        this.loading = false;
      },
    },
  };
</script>
